<template>
  <div class="ant-card" id="teach_list">
    <div class="ant-card-body" v-show="$route.meta.isShow">
      <div class="head-box">
        <div class="table-operator">
          <a-button
            icon="plus"
            type="primary"
            @click="handleEdit(0)"
            v-show="isShowAdd"
            >添加教师</a-button
          >
        </div>
        <a-form layout="inline" :form="searchForm" @submit="handleSearch">
          <a-form-item>
            <a-input v-decorator="['fullName']" placeholder="请输入关键字">
              <a-icon
                slot="prefix"
                type="search"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button icon="search" type="primary" html-type="submit"
              >搜索</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <div class="contant-box">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :pagination="false"
        >
          <!-- 图片处理 -->
          <img
            v-viewer
            class="table-picture"
            slot="pictureUrl"
            slot-scope="text, record"
            :src="record.pictureUrl"
          />
          <span slot="action" slot-scope="text, record">
            <template>
              <a
                v-show="isShowUpd"
                class="btn-edit"
                @click="handleEdit(record.key)"
                >编辑</a
              >
              <a
                v-show="isShowDel"
                class="btn-delete"
                @click="handleDel(record.key)"
                >删除</a
              >
            </template>
          </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination
          :pageNo="pageNo"
          :count="count"
          @showSizeChangeFn="showSizeChangeFn"
        ></MyPagination>
      </div>
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
const columns = [
  {
    title: "账号",
    dataIndex: "userName",
    key: "userName",
    width: "15%",
  },
  {
    title: "头像",
    dataIndex: "pictureUrl",
    key: "pictureUrl",
    width: "120px",
    scopedSlots: { customRender: "pictureUrl" },
  },
  {
    title: "姓名",
    dataIndex: "fullName",
    key: "fullName",
    width: "8%",
  },
  {
    title: "职称",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "标签",
    dataIndex: "labelsText",
    key: "labelsText",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    sorter: (a, b) => {
      let aTime = new Date(a.createTime).getTime();
      let bTime = new Date(b.createTime).getTime();
      return aTime - bTime;
    },
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: "150px",
    scopedSlots: { customRender: "action" },
  },
];
import MyPagination from "@/components/pagination/MyPagination";
import { TeacherListApi, TeacherDelApi } from "@/request/api/teachersManage";
import { debounce } from "../../utils/tools";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    if (codeFn("/admin/course/teacher/saveTeacher")) this.isShowAdd = true;
    if (codeFn("/admin/course/teacher/updTeacher")) this.isShowUpd = true;
    if (codeFn("/admin/course/teacher/delTeacher")) this.isShowDel = true;
  },
  data() {
    return {
      searchForm: this.$form.createForm(this, { name: "horizontal_search" }),
      columns,
      tableLoading: false,
      tableList: [],
      fullName: "",
      count: 1,
      pageNo: 1,
      pageSize: 20,
      isShowAdd: false, // 新增是否隐藏
      isShowUpd: false, // 编辑是否隐藏
      isShowDel: false, // 删除是否隐藏
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/teachersManage/teacherList") {
        this.getTeacherList();
      }
    },
  },
  mounted() {
    this.getTeacherList();
  },
  methods: {
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#teach_list");
    },
    // 分页切换页数
    showSizeChangeFn(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.pageSize = pageSize;
      this.getTeacherList();
    },
    // 教师列表数据
    getTeacherList() {
      this.tableLoading = true;
      TeacherListApi({
        fullName: this.fullName,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          let newList = res.data.data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
          });
          this.count = res.data.count;
          this.tableList = newList;
        }
        this.tableLoading = false;
      });
    },
    // 搜索事件
    handleSearch(e) {
      e.preventDefault();
      this.pageNo = 1;
      this.searchForm.validateFields((err, values) => {
        if (!err) {
          this.fullName = values.fullName != undefined ? values.fullName : "";
          this.tableList = [];
          debounce(this.getTeacherList());
        }
      });
    },
    // 添加 / 编辑教师
    handleEdit(id) {
      if (id == 0) {
        this.$router.push(`/teachersManage/teacherList/addTeacher/${id}`);
      } else {
        location.href = `/teachersManage/teacherList/addTeacher/${id}`;
      }
    },
    // 删除教师
    handleDel(id) {
      const that = this;
      Modal.confirm({
        title: "删除教师后不可恢复，是否确定删除？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          TeacherDelApi({ id: id }).then((res) => {
            if (res.code == 200) {
              message.success("删除成功");
              that.getTeacherList();
            }
          });
        },
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
#teach_list {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.head-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.table-picture {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: contain;
}
.btn-edit {
  margin-right: 30px;
}
</style>